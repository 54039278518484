declare global {
  interface Window {
    fbq: any;
    gtag: any;
    dataLayer: any[];
  }
}

export interface EventOptions {
  value?: number;
  currency?: string;
}

export const event = (name: string, options?: EventOptions) => {
  window.fbq('track', name, options ? { ...options } : {});
};

export const purchaseEvent = (value: number, currency: string) => {
  window.fbq('track', 'Purchase', { value: value, currency: currency });
};
