import React from 'react';
import {
  Stack,
  Text,
  Container,
  TextProps,
  ContainerProps,
  Link,
} from '@chakra-ui/react';

interface HoursAddressProps {
  textProps?: TextProps;
  containerProps?: ContainerProps;
}

export default function HoursAddress({
  textProps,
  containerProps,
}: HoursAddressProps) {
  return (
    <Container
      as={Stack}
      maxW={'100%'}
      direction="column"
      w="full"
      {...containerProps}
    >
      <Text
        color="green"
        fontWeight="semibold"
        textTransform={'uppercase'}
        fontSize="xs"
        {...textProps}
      >
        HORÁRIO DE FUNCIONAMENTO:
      </Text>
      <Text
        lineHeight="2"
        color="white"
        fontWeight="semibold"
        textTransform={'uppercase'}
        fontSize="xs"
        {...textProps}
      >
        Quarta a Sexta: 14h às 22h
        <br />
        Sábado: 10h às 22h
        <br />
        Domingo: 10h as 20h
      </Text>

      <Text
        color="green"
        fontWeight="semibold"
        textTransform={'uppercase'}
        fontSize="xs"
        {...textProps}
      >
        ENDEREÇO:
      </Text>
      <Text
        color="white"
        fontWeight="semibold"
        textTransform={'uppercase'}
        fontSize="xs"
        {...textProps}
      >
        Rua Doutor Rubens Gomes Bueno , 288 - Chácara Santo Antônio - São Paulo
        - SP, 04730-000
      </Text>

      <Text
        color="green"
        fontWeight="semibold"
        textTransform={'uppercase'}
        fontSize="xs"
        {...textProps}
      >
        OUVIDORIA:
      </Text>
      <Text
        color="white"
        fontWeight="semibold"
        textTransform={'uppercase'}
        fontSize="xs"
        {...textProps}
      >
        Estamos aqui para ouvir você.
        <br />
        <Link href="https://wkf.ms/3IuZtSq" textDecoration={'underline'}>
          Clique aqui
        </Link>
      </Text>

      <Text
        color="green"
        fontWeight="semibold"
        textTransform={'uppercase'}
        fontSize="xs"
        {...textProps}
      >
        TRABALHE CONOSCO:
      </Text>
      <Text
        color="white"
        fontWeight="semibold"
        textTransform={'uppercase'}
        fontSize="xs"
        {...textProps}
      >
        <Link href="https://wkf.ms/46d06cH" textDecoration={'underline'}>
          Clique aqui
        </Link>{' '}
        para acessar o formulário.
      </Text>
    </Container>
  );
}
